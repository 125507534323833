export default [
  {
    image: "EasyToSetup.svg",
    imageAlt: "Easy to setup website on Hostylus",
    title: "Easy to setup",
    description:
      "Getting your website live is as simple as a click of a button. Everything you need - provided in a clear way.",
  },
  {
    image: "simplyFast.svg",
    imageAlt: "Simply fast websites hosted on Hostylus",
    title: "Simply fast websites",
    description:
      "Website speed can slow or grow your businessHosting. Delight your visitors with a lightning fast website.",
  },
  {
    image: "call support.svg",
    title: "24/7/365 Chat Support",
    imageAlt: "24/7/365 Chat Support with Hostylus",
    description:
      "Our team of experts will solve technical issues to get your websites up and running. Anytime.",
  },
];
