import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Feature from "../components/featureBlock";
import Accordion from "../components/accordion";
import ImageRight from "../components/imageRight";
import ImageLeft from "../components/imageLeft";
import Landing from "../components/landingSection";
import LI from "../components/plan-li";
import PlanCard from "../components/planSelectCard";
import PricingDiv from "../components/planSelect-li";
import Image from "../components/imageProcessing";
import AccordionContent from "../DB/Accordion-encrypt-protection";
// import Packages from "../DB/buisness-plan-card";
import Features from "../DB/business-features";
import { graphql, useStaticQuery } from "gatsby";

function businessHosting() {
	const data = useStaticQuery(graphql`
		query businessQuery {
			allProducts(filter: { productGroupID: { eq: 11 } }) {
				nodes {
					productID
					productGroupID
					name
					description
					monthly
					quarterly
					semiannually
					annually
					biennially
					triennially
				}
			}
		}
	`);
	return (
		<Layout>
			<SEO
				keywords={[
					"businessHosting email hosting",
					"businessHosting hosting",
					"businessHosting hosting website",
					"businessHosting cloud hosting",
					"how to start web hosting businessHosting",
					"small businessHosting web hosting and email",
					"small businessHosting website hosting and design",
					"best hosting for businessHosting",
					"best businessHosting hosting",
				]}
				title="Business Hosting"
				image={"/hostylus-card-preview.jpg"}
				description="Get your business to where it belongs with Solid Linux Servers & perfect plans that cover long lists of features. A 24/7 Chat Support to solve your problems."
			/>
			<Landing
				header="Business hosting"
				desc="Boost your online reach without worrying about your website’s hosting plans. Our wide range of options will help you achieve your intended goals."
				btnURL="#plans"
				btnText={"get started"}
				image={
					<Image
						image={"busnissLanding_DeyIjjMzGc2r.png"}
						alt={" a man holding a laptop in his hand & pointing at the screen"}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>
			<section id="plans" className="plans max-w-5xl mx-auto my-16 mobile:pb-3">
				<h2 className="text-3xl text-black font-bold text-center">
					Choose Your Best Plan
				</h2>
				<h6 className="text-gray-400 text-center">
					High Quality Plans in Fair Rate
				</h6>

				<div className="grid grid-cols-3 gap-3 flex justify-items-center mt-8 mobile:grid-cols-1 tablet:grid-cols-1 py-8">
					{data.allProducts.nodes.map(function (items, index) {
						var stripedHtml = items.description.replace(/<[^>]+>/g, "");
						var description = stripedHtml.split("\n");
						const divInfo = Array.from(
							{ length: description.length / 2 },
							(_, i) => description[2 * i] + "." + description[2 * i + 1]
						); //every tow elements in description compained into one element in divInfo

						return (
							<PlanCard
								key={index}
								price1m={items.monthly}
								price12m={items.annually}
								price24m={items.biennially}
								price36m={items.triennially}
								header={items.name}
								productID={items.productID}
								myItems={
									<>
										{divInfo.map((listItem, listIndex) => (
											<PricingDiv
												key={listIndex}
												header={listItem.split(".")[0]}
												desc={listItem.split(".")[1].trim()}
											/>
										))}
									</>
								}
							/>
						);
					})}
				</div>
			</section>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<h2 className="text-4xl text-black font-bold text-center">
					Business Hosting Features
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3 mobile:px-2">
					A domain name tells the world who you are and what you do. Search for
					a name, word, or phrase and we’ll help you find the perfect domain
					name to own your piece of the internet.
				</h4>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch mt-6 mobile:grid-cols-1 tablet:grid-cols-1 tablet:px-16 py-8">
					{Features.map((items, key) => (
						<Feature
							key={key}
							image={items.image}
							header={items.title}
							desc={items.description}
							alt={items.imageAlt}
						/>
					))}
				</div>
			</section>
			<ImageRight
				image={
					<Image
						image={"migration_hub_au0D62X0cB.png"}
						alt={"a blue image explaining what hostylus migration includes"}
					/>
				}
				header={"Migration Hub"}
				smallHeader={"Migration has never been easier"}
				desc={
					"Fear no loss in data or ranking while migrating your website with recovery plans that prevent any imposter from sneak peeking at your data"
				}
				btnLink={"#plans"}
				btnText={"MIGRATE YOUR SITE"}
				hideShopNow={"hidden"}
			/>
			<ImageLeft
				image={
					<Image
						image={"solid_linux_d6CJdfO4z.png"}
						alt={"blue icon with servers inside"}
					/>
				}
				header={"Solid Linux Servers"}
				desc={
					<ul
						style={{
							listStyleImage:
								"url('https://ik.imagekit.io/softylus/blueTick_nzCxIZ4xA.svg')",
							listStylePosition: "inside",
							textAlign: "left",
						}}
						className="list-disc"
					>
						<LI
							LI={"24/7 support from our hosting experts."}
							textSize={"text-lg"}
						/>
						<LI
							LI={"Application installers including WordPress Hosting"}
							textSize={"text-lg"}
						/>
						<LI LI={"FREE migration to our servers"} textSize={"text-lg"} />
						<LI LI={"Latest PHP 7.0 - 7.4"} textSize={"text-lg"} />
						<LI LI={"Daily backups"} textSize={"text-lg"} />
						<LI LI={"Firewall + Security package"} textSize={"text-lg"} />
					</ul>
				}
				hideShopNow={"hidden"}
				hideLearnMore={"hidden"}
			/>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl">
					<h2 className="capitalize text-center text-black mb-16 text-4xl font-bold uppercase">
						Entery-level Let’s encrypt protection
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}
export default businessHosting;
